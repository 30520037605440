export const firebaseConfig = {
  apiKey: "AIzaSyB-x9uo-OxnZiEa0ppflhKX3UxUp3U7xeE",
  authDomain: "soundboard-b0ad1.firebaseapp.com",
  databaseURL: "https://soundboard-b0ad1-default-rtdb.firebaseio.com",
  projectId: "soundboard-b0ad1",
  storageBucket: "soundboard-b0ad1.appspot.com",
  messagingSenderId: "921644956772",
  appId: "1:921644956772:web:8cd71fe3f2455c0ade3371",
  measurementId: "G-EW8L7W3J8H",
};
